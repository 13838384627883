import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	button: {
		background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
		border: 0,
		borderRadius: 18,
		boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
		color: 'white',
		height: 48,
		padding: '0 30px',
	},
});

export default function Button(props) {
	const classes = useStyles();
	return (
		<MuiButton variant="contained" color="primary" {...props} className={classes.button}>
			{props.children}
		</MuiButton>
	);
}

export { Button };
